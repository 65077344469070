* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
	color: var(--color--main-dark);
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	// overflow: hidden;
}

img {
	object-fit: contain;
}

button {
	cursor: pointer;
	border: none;
	user-select: none;
}

.none-media {
	opacity: 0;
	height: 1px;
	width: 1px;
	position: absolute;
	z-index: -99999;
	bottom: 0;
	display: none;
}

#file-input,
#upsert-input,
#intro-cover,
#book-cover {
	display: none;
}

.simplebar-scrollbar::before {
  background: #E2E2E6 !important;
  width: 6px;
}

.simplebar-content-wrapper {
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
  border-bottom: 1px solid transparent !important;

	&:focus-visible {
		outline: none;
	}
}

.simplebar-vertical {
  margin: 6px 6px;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
